import { Theme } from '@mui/material/styles'
import { Box, Typography, useMediaQuery } from '@mui/material'

import Profile from './Profile'

import useConfig from 'hooks/useConfig'
import DrawerHeader from 'layout/Dashboard/Drawer/DrawerHeader'

import { MenuOrientation } from 'types/config'
import { useEffect, useState } from 'react'
import { useMenuItems } from 'menu-items'
import { NavItemType } from 'types/menu'
import { useLocation } from 'react-router'

const HeaderContent = () => {
  const { menuOrientation } = useConfig()
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  const menuItems = useMenuItems()
  const location = useLocation()
  const [item, setItem] = useState<NavItemType | null>(null)
  let customLocation = location.pathname
  useEffect(() => {
    const findActiveItem = (menu: NavItemType) => {
      if (menu.url === customLocation) {
        setItem(menu)
        return true
      }
      if (menu.children) {
        return menu.children.some(findActiveItem)
      }
      return false
    }

    menuItems?.items?.forEach((menu: NavItemType) => {
      if (menu.type === 'group') {
        findActiveItem(menu)
      }
    })
  }, [menuItems, customLocation])

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && (
        <DrawerHeader open={true} />
      )}
      <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
        <Typography
          variant="h3"
          sx={{ '& .reviews-badge': { display: 'none' } }}
        >
          {item?.title}
        </Typography>
      </Box>
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
      <Profile />
    </>
  )
}

export default HeaderContent
