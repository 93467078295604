export const EmployeeRoles = [
  { value: "admin", label: "Адмін" },
  { value: "moderator", label: "Модератор" },
  { value: "seo", label: "SEO" },
];

export const EmployeeRolesMap = EmployeeRoles.reduce((acc: {[index: string]:string}, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

export enum EmployeeRolesEnum {
  admin = "admin",
  moderator = "moderator",
  seo = "seo",
}

export enum EmployeeStatus {
  active = "active",
  inactive = "inactive",
}
