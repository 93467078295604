// project import
import main from "./main";
import values from "./values";
import tools from "./tools";
import adminValues from "./adminValues";

// types
import { NavItemType } from "types/menu";

import { useLayoutEffect, useState } from "react";
import useAuth from "hooks/useAuth";

export function useMenuItems() {
  const [menuItems, setMenuItems] = useState<{ items: NavItemType[] }>({
    items: [],
  });
  const { user } = useAuth();
  let userRoles : string[] | [] = [];
  if (user) userRoles = user.roles;

  useLayoutEffect(() => {
    setMenuItems({ items: filterMenuByRole([main, values, adminValues, tools], userRoles) });
    // eslint-disable-next-line
  }, [user]);

  return menuItems;
}

function filterMenuByRole(items: NavItemType[], roles: string[]):  NavItemType[] {
  // @ts-ignore
  return items.map(item => {
    if (item.roles && !item.roles.some(role => roles.includes(role))) {
      return null;
    }
    const filteredChildren = item.children ? filterMenuByRole(item.children, roles) : [];
    return { ...item, children: filteredChildren.filter(child => child !== null) };
  }).filter(item => item !== null);
}
