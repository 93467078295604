import { Dayjs } from 'dayjs'
import { CustomFile } from './dropzone'
import { BUSINESS_PROPERTY_CATEGORY } from 'utils/constants'
import { ICitySearchType } from './city'

export type BusinessType = {
  _id: string
  address: string
  address_details: string
  city: string
  country: string
  description: string
  district: string
  hash: string
  categories: CategoryType[]
  lineOfBusiness: string
  location: string
  moderated: boolean
  phones: string[]
  photos: BusinessPhotoType[]
  properties: PropertiesType
  rating: number
  seoDescription: string
  seoKeywords: string[]
  seoTitle: string
  services: string[]
  socialLinks?: { instagram: string }
  slug: string
  status: string
  subCategories: string[]
  subscriptions: any[]
  title: string
  type: string
  website: string
  notShowAddress: boolean
  adultType: AdultTypeEnum
  isOnline: boolean
  workSchedule: WorkScheduleType
  withoutSchedule: boolean
  workOutsideOffice: boolean
  workOutside: WorkOutsideType[]
}

export enum AdultTypeEnum {
  all = 'all',
  onlyChildren = 'onlyChildren',
  onlyAdult = 'onlyAdult',
}

export interface CategoryType {
  _id: string
  name: string
  icon: string
}

export interface WorkOutsideType {
  city: string
  cityPlaceId: string
  districts?: string[]
}

export interface BusinessPhotoType {
  _id: string
  businessId: string
  description: string
  order: number
  status: BusinessPhotoStatusEnum
  storagePath: string
}

export interface BusinessPhotoTypeLocal extends BusinessPhotoType {
  file: CustomFile //only local
  loadingPreview: string //only local
  name: string //only local
  size: number //only local
  type: string //only local
}

export enum BusinessPhotoStatusEnum {
  prepared = 'prepared',
  uploaded = 'uploaded',
}

export interface PropertiesType {
  // Доступність
  wheelchairAccessibleEntrance?: boolean //Вхід для людей у колісних кріслах
  wheelchairAccessibleParking?: boolean //Паркінг для людей у колісних кріслах
  wheelchairAccessibleSeating?: boolean //Місця для людей у колісних кріслах
  wheelchairAccessibleRestroom?: boolean //Туалет із доступом для людей у колісних кріслах

  //Зручності
  restroom?: boolean //Туалет
  //Безкоштовний Wi-Fi
  //Wi-Fi

  //Планування
  reservable?: boolean //Бронювання

  // Варіанти розрахунку //Розрахунки
  acceptsDebitCards?: boolean //Дебетові картки
  acceptsCreditCards?: boolean // Кредитні картки
  acceptsNfc?: boolean //Мобільні платежі через NFC
  acceptsCashOnly?: boolean //Лише готівка

  //Діти
  goodForChildren?: boolean //Підходить для дітей

  //Хатні тварини
  allowsDogs?: boolean //Можна із собаками

  //Варіанти парковки
  freeParkingLot?: boolean //Безкоштовна парковка
  freeStreetParking?: boolean //Безкоштовна парковка на вулиці
  paidParkingLot?: boolean //Платна парковка
  paidStreetParking?: boolean //Платна парковка на вулиці
  valetParking?: boolean //Парковка для персоналу
  freeGarageParking?: boolean //Безкоштовна парковка в гаражі
  paidGarageParking?: boolean //Платна парковка в гаражі
}

export interface WorkScheduleType {
  Пн: string[]
  Вт: string[]
  Ср: string[]
  Чт: string[]
  Пт: string[]
  Сб: string[]
  Нд: string[]
}

export interface IDayScheduleEditType {
  key: keyof WorkScheduleType
  title: string
  index: number
  close: boolean
  from: Dayjs | null
  to: Dayjs | null
}

export interface BusinessNoteType {
  _id: string
  businessId: string
  user: BusinessNoteUserType
  text: string
  createdAt: Date
}

export interface BusinessNoteUserType {
  _id: string
  email: string
  roles: string[]
  firstname: string
  lastname: string
  status: string
  position: string
}

export enum BusinessTypeEnum {
  individual = 'individual',
  business = 'business',
}

export interface IBusinessProperty {
  key: string
  title: string
  category: BUSINESS_PROPERTY_CATEGORY
  index: number
}

// Filter Business page
export interface FilterCategories {
  _id: string
  name: string
}

export interface FilterSubCategories {
  _id: string
  name: string
  parentId: string
}

export interface FilterService {
  _id: string
  name: string
}

export interface FilterBrands {
  _id: string
  title: string
}

export enum SelectBooleanEnum {
  all = 'all',
  true = 'true',
  false = 'false',
}

export interface FilterBusiness {
  pagination: {
    pageIndex: number
    pageSize: number
  }
  sorting: { id: string; desc: boolean }[]
  search: string
  phone: string
  status: 'archived' | undefined
  type: BusinessTypeEnum | null
  cities: ICitySearchType[]
  createdFrom: Date | undefined
  createdTo: Date | undefined
  categories: FilterCategories[]
  subCategories: FilterSubCategories[]
  services: FilterService[]
  brands: FilterBrands[]
  ratingMin: string | null
  ratingMax: string | null
  reviewCountMin: string | null
  reviewCountMax: string | null
  photosCountMin: string | null
  photosCountMax: string | null
  moderated: SelectBooleanEnum
  websiteExists: SelectBooleanEnum
  instagramExists: SelectBooleanEnum
  withoutSchedule: SelectBooleanEnum
  isOnline: boolean
  adultType: AdultTypeEnum | undefined
}

export type ExportParamsType = {
  password: string
  fields: string
  categories?: string | undefined
  services?: string | undefined
  subcategories?: string | undefined
  brands?: string | undefined
  cities?: string | undefined
  createdFrom?: string | undefined
  createdTo?: string | undefined
  ratingMin?: number | null
  ratingMax?: number | null
  reviewCountMin?: number | null
  reviewCountMax?: number | null
  photosCountMin?: number | null
  photosCountMax?: number | null
  moderated: SelectBooleanEnum | undefined
  websiteExists: SelectBooleanEnum | undefined
  instagramExists: SelectBooleanEnum | undefined
  withoutSchedule: SelectBooleanEnum | undefined
  isOnline: boolean | undefined
  adultType?: AdultTypeEnum | undefined
  type?: BusinessTypeEnum | null
  search?: string
  phone?: string
}
