import { fetcher, fetcherPost } from 'utils/axios'
import useSWR, { mutate } from 'swr'
import {
  CountReviewsOnModerationType,
  FilterReviewsType,
  ModerationReview,
  Review,
} from 'types/review'

export const endpoints = {
  onModeration: '/reviews/onModeration',
  reviews: '/reviews',
}

export async function getReviewsOnModeration(): Promise<CountReviewsOnModerationType> {
  return await fetcher(endpoints.onModeration)
}

export async function getReviews(filter: FilterReviewsType): Promise<{
  data: Review[]
  total: number
}> {
  return await fetcher([
    `${endpoints.reviews}`,
    {
      params: filter,
    },
  ])
}

export const useGetReview = (id: string) => {
  return useSWR<ModerationReview>(`${endpoints.reviews}/${id}`, fetcher)
}

export const approveReview = async (id: string): Promise<void> => {
  try {
    await fetcherPost(`${endpoints.reviews}/${id}/approve`)
    mutate(`${endpoints.reviews}/${id}`)
  } catch (error) {
    console.error(error)
  }
}

export const rejectReview = async (
  id: string,
  reason: string
): Promise<void> => {
  try {
    await fetcherPost([`${endpoints.reviews}/${id}/reject`, { reason }])
    mutate(`${endpoints.reviews}/${id}`)
  } catch (error) {
    console.error(error)
  }
}
