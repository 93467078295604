// third-party
import { PeopleAltOutlined, ImportContacts } from '@mui/icons-material';

// type
import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS - PAGES ||============================== //

const AdminValues: NavItemType = {
  id: "admin",
  type: "group",
  roles: ['admin'],
  children: [
    {
      id: "users",
      title: "Співробітники",
      type: "collapse",
      icon: PeopleAltOutlined,
      children: [
        {
          id: "employees",
          title: "Співробітники",
          type: "item",
          url: "/employees",
          icon: PeopleAltOutlined,
        },
        {
          id: "logs",
          title: "Логування",
          type: "item",
          url: "/audit-logs",
          icon: ImportContacts,
        },
      ]
    }
  ],
};

export default AdminValues;
