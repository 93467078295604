// third-party
import { FormattedMessage } from "react-intl";
import {
  LocationCityOutlined,
  ClassOutlined,
  Inventory,
  BrandingWatermarkOutlined,
  AutoStories,
  Signpost,
  StickyNote2,
} from "@mui/icons-material";

// type
import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS - PAGES ||============================== //

const values: NavItemType = {
  id: "other",
  type: "group",
  roles: ['admin', 'seo'],
  children: [
    {
      id: "registers",
      title: <FormattedMessage id="registers" />,
      type: "collapse",
      icon: Inventory,
      roles: ['admin'],
      children: [
        {
          id: "services",
          title: <FormattedMessage id="services" />,
          type: "item",
          url: "/services",
          icon: ClassOutlined,
        },
        {
          id: "cities",
          title: <FormattedMessage id="cities" />,
          type: "item",
          url: "/cities",
          icon: LocationCityOutlined,
        },
        {
          id: "brands",
          title: <FormattedMessage id="brands" />,
          type: "item",
          url: "/brands",
          icon: BrandingWatermarkOutlined,
        },
      ],
    },
    {
      id: "custom-pages",
      title: "Кастомні сторінки",
      type: "collapse",
      icon: AutoStories,
      roles: ['admin', 'seo'],
      children: [
        {
          id: "filtered-pages-categories",
          title: "Категорії",
          type: "item",
          url: "/filtered-pages-categories",
          icon: ClassOutlined,
        },
        {
          id: "filtered-pages",
          title: "Сторінки",
          type: "item",
          url: "/filtered-pages",
          icon: AutoStories,
        },
      ],
    },

    {
      id: "blog",
      title: <FormattedMessage id="blog" />,
      type: "collapse",
      icon: Signpost,
      roles: ['admin', 'seo'],
      children: [
        {
          id: "blog-categories",
          title: <FormattedMessage id="categories" />,
          type: "item",
          url: "/blog-categories",
          icon: ClassOutlined,
        },
        {
          id: "blog-posts",
          title: <FormattedMessage id="posts" />,
          type: "item",
          url: "/blog-posts",
          icon: StickyNote2,
        },
      ],
    },
  ],
};

export default values;
