import { Box, CircularProgress } from '@mui/material'
import { getReviewsOnModeration } from 'api/review'
import { FormattedMessage } from 'react-intl'
import useSWR from 'swr'

export default function ReviewsBadge() {
  const { data: reviewsOnModeration, isLoading } = useSWR(
    'reviewsOnModeration',
    getReviewsOnModeration
  )

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <FormattedMessage id="reviews" />
      <Box className="reviews-badge">
        {isLoading ? (
          <CircularProgress size={16} />
        ) : (
          <Box
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: 'bold',
            })}
          >
            {`(${reviewsOnModeration?.count})`}
          </Box>
        )}
      </Box>
    </Box>
  )
}
