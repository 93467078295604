// types
import {
  DefaultConfigProps,
  MenuOrientation,
  ThemeDirection,
  ThemeMode,
} from 'types/config'

// ==============================|| THEME CONSTANT ||============================== //

export const twitterColor = '#1DA1F2'
export const facebookColor = '#3b5998'
export const linkedInColor = '#0e76a8'

export const APP_DEFAULT_PATH = '/'
export const HORIZONTAL_MAX_ITEM = 7
export const DRAWER_WIDTH = 260
export const MINI_DRAWER_WIDTH = 60

export const WEBSITE_URL = 'https://kamon.ua'
export const IMAGE_API_URL = 'https://images.kamon.ua'
export const IMAGE_GOOGLE_STORAGE_URL =
  'https://storage.googleapis.com/static-kamon-1402'

// ==============================|| THEME CONFIG ||============================== //

const config: DefaultConfigProps = {
  fontFamily: `'Inter', sans-serif`,
  i18n: 'uk',
  menuOrientation: MenuOrientation.VERTICAL,
  miniDrawer: false,
  container: true,
  mode: ThemeMode.LIGHT,
  presetColor: 'default',
  themeDirection: ThemeDirection.LTR,
}

export default config
