import { lazy } from 'react'

import Loadable from 'components/Loadable'
import DashboardLayout from 'layout/Dashboard'
import { ROUTES } from 'utils/constants/routes'

const DashboardPage = Loadable(lazy(() => import('pages/dashboard')))
const ServicesPage = Loadable(lazy(() => import('pages/services')))
const BrandsPage = Loadable(lazy(() => import('pages/brands')))
const CitiesPage = Loadable(lazy(() => import('pages/cities')))
const BusinessesPage = Loadable(lazy(() => import('pages/business')))
const BusinessPage = Loadable(lazy(() => import('pages/businessEditPage')))
const FilteredPagesListPage = Loadable(
  lazy(() => import('pages/filtered-pages'))
)
const FilteredPage = Loadable(lazy(() => import('pages/filteredPage')))
const BlogPostsPage = Loadable(lazy(() => import('pages/blog-posts')))
const BlogPostPage = Loadable(lazy(() => import('pages/blogPostPage')))
const BlogCategories = Loadable(lazy(() => import('pages/blog-categories')))
const FilteredPageCategories = Loadable(
  lazy(() => import('pages/filteredPage-categories'))
)
const ReviewsPage = Loadable(lazy(() => import('pages/reviews')))
const ReviewsEditPage = Loadable(lazy(() => import('pages/reviews-edit')))
const NotFoundPage = Loadable(lazy(() => import('pages/maintenance/404')))
const CustomersPage = Loadable(lazy(() => import('pages/customers')))

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: '/',
          element: <DashboardPage />,
        },
        {
          path: '/services',
          element: <ServicesPage />,
        },
        {
          path: '/cities',
          element: <CitiesPage />,
        },
        {
          path: '/brands',
          element: <BrandsPage />,
        },
        {
          path: ROUTES.BUSINESSES,
          element: <BusinessesPage />,
        },
        {
          path: ROUTES.REVIEWS,
          element: <ReviewsPage />,
        },
        {
          path: ROUTES.CUSTOMERS,
          element: <CustomersPage />,
        },
        {
          path: '/filtered-pages',
          element: <FilteredPagesListPage />,
        },
        {
          path: '/filtered-pages-categories',
          element: <FilteredPageCategories />,
        },
        {
          path: '/blog-posts',
          element: <BlogPostsPage />,
        },
        {
          path: '/blog-categories',
          element: <BlogCategories />,
        },
      ],
    },
    {
      path: ROUTES.BUSINESSES_EDIT,
      element: <BusinessPage />,
    },
    {
      path: ROUTES.REVIEWS_EDIT,
      element: <ReviewsEditPage />,
    },
    {
      path: '/filtered-pages/:id',
      element: <FilteredPage />,
    },
    {
      path: '/blog-posts/:id',
      element: <BlogPostPage />,
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ],
}

export default MainRoutes
