import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainRoutes from "./MainRoutes";

const EmployeesListPage = Loadable(lazy(() => import("pages/employees")));
const EmployeesProfilePage = Loadable(
  lazy(() => import("pages/employeeProfile")),
);
const LogsPage = Loadable(lazy(() => import("pages/audit-logs")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutesAdmin = MainRoutes;
MainRoutesAdmin.children = [
  ...MainRoutesAdmin.children.map((route) =>
    route.path === "/"
      ? {
          ...route,
          children: [
            ...(route.children as any),
            {
              path: "/employees",
              element: <EmployeesListPage />,
            },
            {
              path: "/audit-logs",
              element: <LogsPage />,
            },
          ],
        }
      : route,
  ),
  {
    path: "/employees/:id",
    element: <EmployeesProfilePage />,
  },
];

export default MainRoutesAdmin;
