import { createBrowserRouter } from "react-router-dom";

// project import
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";
import MainRoutesAdmin from "./MainRoutesAdmin";

// ==============================|| ROUTING RENDER ||============================== //

export const AuthRouter = createBrowserRouter([LoginRoutes], {
  basename: process.env.REACT_APP_BASE_NAME,
});
export const MainRouter = createBrowserRouter([MainRoutes], {
  basename: process.env.REACT_APP_BASE_NAME,
});
export const AdminRouter = createBrowserRouter([MainRoutesAdmin], {
  basename: process.env.REACT_APP_BASE_NAME,
});
