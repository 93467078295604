import { RouterProvider } from 'react-router-dom'
import 'react-image-crop/dist/ReactCrop.css'
import { AuthRouter, MainRouter, AdminRouter } from 'routes'
import ThemeCustomization from 'themes'
import Locales from 'components/Locales'
import ScrollTop from 'components/ScrollTop'
import Snackbar from 'components/@extended/Snackbar'
import Notistack from 'components/third-party/Notistack'
import { JWTProvider as AuthProvider } from 'contexts/JWTContext'
import useAuth from 'hooks/useAuth'
import { EmployeeRolesEnum } from './utils/constants'

const RootComponent = () => {
  const { isLoggedIn, user } = useAuth()

  const mainRouter =
    user?.roles && user.roles.includes(EmployeeRolesEnum.admin) ? (
      <RouterProvider router={AdminRouter} />
    ) : (
      <RouterProvider router={MainRouter} />
    )

  return isLoggedIn ? mainRouter : <RouterProvider router={AuthRouter} />
}

const App = () => (
  <ThemeCustomization>
    <Locales>
      <ScrollTop>
        <AuthProvider>
          <>
            <Notistack>
              <RootComponent />
              <Snackbar />
            </Notistack>
          </>
        </AuthProvider>
      </ScrollTop>
    </Locales>
  </ThemeCustomization>
)

export default App
