import { IBusinessProperty, AdultTypeEnum } from 'types/business'

export const BusinessTypes = [
  { value: 'individual', label: 'Спеціаліст' },
  { value: 'business', label: 'Компанія' },
]

export const enum BUSINESS_PROPERTY_CATEGORY {
  accessibility = 'Доступність',
  amenities = 'Зручності',
  planning = 'Планування',
  calculations = 'Розрахунки',
  children = 'Діти',
  domesticAnimals = 'Хатні тварини',
  parkingOptions = 'Варіанти парковки',
}

export const AdultTypeLabels = {
  [AdultTypeEnum.all]: 'Для дітей та дорослих',
  [AdultTypeEnum.onlyChildren]: 'Тільки для дітей',
  [AdultTypeEnum.onlyAdult]: 'Тільки для дорослих',
}

export const BUSINESS_PROPERTIES: IBusinessProperty[] = [
  // accessibility
  {
    key: 'wheelchairAccessibleEntrance',
    title: 'Вхід для людей у колісних кріслах',
    category: BUSINESS_PROPERTY_CATEGORY.accessibility,
    index: 1, //for sorting only
  },
  {
    key: 'wheelchairAccessibleParking',
    title: 'Паркінг для людей у колісних кріслах',
    category: BUSINESS_PROPERTY_CATEGORY.accessibility,
    index: 2,
  },
  {
    key: 'wheelchairAccessibleSeating',
    title: 'Місця для людей у колісних кріслах',
    category: BUSINESS_PROPERTY_CATEGORY.accessibility,
    index: 3,
  },
  {
    key: 'wheelchairAccessibleRestroom',
    title: 'Туалет із доступом для людей у колісних кріслах',
    category: BUSINESS_PROPERTY_CATEGORY.accessibility,
    index: 4,
  },

  // amenities
  {
    key: 'restroom',
    title: 'Туалет',
    category: BUSINESS_PROPERTY_CATEGORY.amenities,
    index: 1,
  },

  // planning
  {
    key: 'reservable',
    title: 'Бронювання',
    category: BUSINESS_PROPERTY_CATEGORY.planning,
    index: 1,
  },

  // calculations
  {
    key: 'acceptsDebitCards',
    title: 'Дебетові картки',
    category: BUSINESS_PROPERTY_CATEGORY.calculations,
    index: 1,
  },
  {
    key: 'acceptsCreditCards',
    title: 'Кредитні картки',
    category: BUSINESS_PROPERTY_CATEGORY.calculations,
    index: 2,
  },
  {
    key: 'acceptsNfc',
    title: 'Мобільні платежі через NFC',
    category: BUSINESS_PROPERTY_CATEGORY.calculations,
    index: 3,
  },
  {
    key: 'acceptsCashOnly',
    title: 'Лише готівка',
    category: BUSINESS_PROPERTY_CATEGORY.calculations,
    index: 4,
  },

  // children
  {
    key: 'goodForChildren',
    title: 'Підходить для дітей',
    category: BUSINESS_PROPERTY_CATEGORY.children,
    index: 1,
  },

  // domesticAnimals
  {
    key: 'allowsDogs',
    title: 'Можна із собаками',
    category: BUSINESS_PROPERTY_CATEGORY.domesticAnimals,
    index: 1,
  },

  // parkingOptions
  {
    key: 'freeParkingLot',
    title: 'Безкоштовна парковка',
    category: BUSINESS_PROPERTY_CATEGORY.parkingOptions,
    index: 1,
  },
  {
    key: 'freeStreetParking',
    title: 'Безкоштовна парковка на вулиці',
    category: BUSINESS_PROPERTY_CATEGORY.parkingOptions,
    index: 2,
  },
  {
    key: 'paidParkingLot',
    title: 'Платна парковка',
    category: BUSINESS_PROPERTY_CATEGORY.parkingOptions,
    index: 3,
  },
  {
    key: 'paidStreetParking',
    title: 'Платна парковка на вулиці',
    category: BUSINESS_PROPERTY_CATEGORY.parkingOptions,
    index: 4,
  },
  {
    key: 'freeGarageParking',
    title: 'Безкоштовна парковка в гаражі',
    category: BUSINESS_PROPERTY_CATEGORY.parkingOptions,
    index: 5,
  },
  {
    key: 'paidGarageParking',
    title: 'Платна парковка в гаражі',
    category: BUSINESS_PROPERTY_CATEGORY.parkingOptions,
    index: 6,
  },
  {
    key: 'valetParking',
    title: 'Парковка для персоналу',
    category: BUSINESS_PROPERTY_CATEGORY.parkingOptions,
    index: 7,
  },
]

export const MockProperties = {
  // Доступність //accessibility
  wheelchairAccessibleEntrance: undefined, //Вхід для людей у колісних кріслах
  wheelchairAccessibleParking: undefined, //Паркінг для людей у колісних кріслах
  wheelchairAccessibleSeating: undefined, //Місця для людей у колісних кріслах
  wheelchairAccessibleRestroom: undefined, //Туалет із доступом для людей у колісних кріслах

  //Зручності //amenities
  restroom: undefined, //Туалет
  //Безкоштовний Wi-Fi
  //Wi-Fi

  //Планування //planning
  reservable: undefined, //Бронювання

  //Розрахунки //calculations
  acceptsDebitCards: undefined, //Дебетові картки
  acceptsCreditCards: undefined, //Кредитні картки
  acceptsNfc: undefined, //Мобільні платежі через NFC
  acceptsCashOnly: undefined, //Лише готівка

  //Діти //children
  goodForChildren: undefined, //Підходить для дітей

  //Хатні тварини //domesticAnimals
  allowsDogs: undefined, //Можна із собаками

  // Варіанти парковки //parkingOptions
  freeParkingLot: undefined, //Безкоштовна парковка
  freeStreetParking: undefined, //Безкоштовна парковка на вулиці
  paidParkingLot: undefined, //Платна парковка
  paidStreetParking: undefined, //Платна парковка на вулиці
  freeGarageParking: undefined, //Безкоштовна парковка в гаражі
  paidGarageParking: undefined, //Платна парковка в гаражі
  valetParking: undefined, //Парковка для персоналу
}
